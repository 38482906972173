import { VDataTable } from "vuetify/lib/labs/components.mjs";

export interface countries {
  id: number;
  isoCode: string;
  nameEn: string;
  flag: string;
}

export interface Providers {
  id: number,
  dingCode: string,
  countryId: number,
  nameEn: string,
  phoneRegex: string,
  logoUrl: string

}

export interface PopularProducts {
    popularity: number,
    id: number,
    description: string,
    maxPrice: Price,
    minPrice : Price,
    provider : Omit<Providers , "phoneRegex">
    fixedAmount?: boolean;
}
export interface Product {
  id: number,
  providerId: number,
  processingMode: string,
  redemptionMechanism: string,
  testNumber: string,
  description: string,
  benefits: string[],
  maxPrice: Price,
  minPrice: Price,
  fixedAmount?: boolean;
}
export interface Price {
  taxRate: number,
  taxName: string,
  taxCalculation: string,
  receiveValue: number,
  receiveValueExcludingTax: number,
  receiveCurrency: Currency,
  sendValue: number,
  sendCurrency: Currency
}
export interface Currency {
  isoCode: string,
  nameEn: string
}

export enum Roles {
  Customer = "customer",
  Admin = "admin",
  Supervisor = "supervisor"
}

export interface Profile {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  balance?: number,
  role: string,
  createdAt: string,
  token?: string,
}

export type Transaction = {
  id: number,
  amount: number,
  currency: {
    isoCode: string,
    nameEn: string,
  },
  byUser: {
    id: number,
    name: string,
  },
  toUser: {
    id: number,
    name: string
  },
  createdAt: Date
}
export type VDataTableHeader = VDataTable["headers"];