import { createRouter, createWebHashHistory } from "vue-router";

export interface InfoMeta {
  /* Accepting md and html */
  // Example "info/full-vm-md"
  // Note: add page path as absolute one
  // so it get prefixed with current baseUrl
  page: string;
  tooltip?: string;
}

export interface RouteMeta {
  title: string;
  info?: InfoMeta;
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      component: () => import("../views/home.vue"),
      meta: { title: "Al-Rahma" },
    },
    {
      path: "/login",
      component: () => import("../views/login.vue"),
      meta: { title: "Al-Rahma login" },
    },{
      path: '/profile',
      component: ()=> import("../views/profile.vue"),
      meta: {title: "Profile"}
    },{
      path: '/users',
      component: ()=> import("../views/Users.vue"),
      meta:{
        title: 'Users'
      }
    },{
      path: '/charge',
      component: ()=> import("../views/charge.vue"),
      meta:{
        title: 'Al-Rahma Charge'
      }
    }


  ],
});

export default router;
