import axios from "axios"
export default {
  async register( context :any,payload : any){
   const response =await  axios.post(`${process.env.VUE_APP_BASEURL}auth/signup`, {
      firstName:payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      password: payload.password,  
  });
  context.commit('setToken', response.data['token'])
},
  async login(context :any ,payload:any){
    const response =await  axios.post(`${process.env.VUE_APP_BASEURL}auth/login`, {
      email: payload.email,
      password: payload.password
  });
  context.commit('setToken', response.data['token'])
  }
}