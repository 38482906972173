import mutations from "./mutations"
import getters from "./getters"
import actions from "./actions"
import { Roles } from "@/types"
import jwt_decode from "jwt-decode";
export default {
  state() {
    const token = localStorage.getItem("token") 
    return {
      token,
      role: token ? (jwt_decode(token) as { role: Roles }).role : Roles.Customer
    }
  },
  mutations,
  actions,
  getters
}