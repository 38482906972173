import { Roles } from "@/types";

export default {
  userToken(state: { token: null | string }) {
    return state.token;
  },
  isLoggedIn(state: { token: null | string }): boolean {
    return !!state.token;
  },
  isAdmin(state: { role: Roles }) {
    return state.role == Roles.Admin
  },
  isSupervisor(state: { role: Roles }) {
    return state.role == Roles.Supervisor
  },
}