import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { loadFonts } from "./plugins/webfontloader";
import FontAwesomeIcon from "@/util/fontawesome-icons.";
import store from "../src/store/index"
import Particles from "@tsparticles/vue3";
import {Engine} from "@tsparticles/engine"
import { loadFull } from "tsparticles";
loadFonts();

createApp(App).component('FontAwesomeIcon',FontAwesomeIcon).use(Particles, {
  init: async (engine:Engine) => {
    await loadFull(engine)
  },
}).use(store).use(router).use(vuetify).mount("#app");
