import { Roles } from "@/types";
import jwt_decode from "jwt-decode";

export default {
  setToken(state: { token: null | string, role: Roles }, token: string) {
    state.token = token
    state.role = (jwt_decode(token) as { role: Roles }).role
    localStorage.setItem("token", token);
  },

  removeToken(state: { token: string | null }) {
    state.token = null
    localStorage.removeItem("token")
  }
}